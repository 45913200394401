<template>
<section class="brandinfo-wrapper">
    <h1 class="header-title">修改轮播图</h1>

    <!-- form -->
    <el-form class="form-content" ref="form"  label-width="10px" label-position="left">
        <el-form-item label="">
            <p class="tip">支持png，jpeg，jpg格式，最多可上传5张；图片大小不能超过3MB。</p>
            <el-upload
                action=""
                :http-request="uploadHandler"
                list-type="picture-card"
                :limit="5"
                :before-upload="beforeUploadHandler"
                :file-list="fileList"
                :on-change="logoChangeHandler"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove">
                <p class="upload-custom flex flex-column align-center flex-center">
                    <i class="el-icon-plus el-icon"></i><br/>
                    <span >添加上传图片</span>
                </p>
            </el-upload>
        </el-form-item>
        <el-form-item>
            
        </el-form-item>
    </el-form>
    <div class="footer-btn">
        <el-button>取消</el-button>
        <el-button type="primary" @click="saveHandler">保存</el-button>
    </div>
    <!-- 图片放大 -->
    <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
</section>
</template>

<script>
import utils from '@/utils/utils.js';

let init = false;
export default {
    data(){
        return {
            
            fileList:[],
            dialogVisible:false,
            dialogImageUrl:''
        }
    },
    created(){
        this.getBannerList(); 
    },
    methods:{
        handlePictureCardPreview(file){
            this.dialogVisible = true;
            this.dialogImageUrl = file.url;
        },
        handleRemove(file,fileList){
            console.log(file,fileList);
            this.fileList.forEach((item,i)=>{
                if(item.uid == file.uid){
                    let data = this.fileList.splice(i,1);
                    console.log(data);
                    this.$https.delImage([data[0].fileid]);
                }
            })
        },
        beforeUploadHandler(file){
            const isLt3M = file.size / 1024 / 1024 < 3;
            if (!isLt3M) {
            this.$message.error('上传图片大小不能超过 3MB!');
            }
            return isLt3M;
        },
        async uploadHandler(file,fileList){
            const localPath = utils.Img_URL(file.file);
            const rs = await this.$https.uploadImage('test',file.file);
            console.log(rs)
            if(!rs) return;
            this.fileList.push({
                url: localPath,
                fileid: rs,
                uid: file.file.uid
            });
            console.log(this.fileList);
        },
        logoChangeHandler(file,fileList){
            console.log(file,fileList)
        },
        
        async getBannerList(){
            const rs = await this.$https.cloud({
                action:'global',
                type:'getBanner'
            });
            if(rs.code!=0) return;
            const imgList = rs.data.list[0]&&rs.data.list[0].data.activitySlideshow.value;
            imgList.forEach((item,i)=>{
                imgList[i] = {fileid:item};
            });
            const fileList = await this.$https.getImagePath(imgList);
            fileList.forEach(item=>{
                item.url = item.download_url
            })
            console.log(fileList);
            this.fileList = fileList;

        },
        async saveHandler(){
            if(init){
                this.$message.error('请不要重复提交');
                return;
            }
            const fileList = [];
            this.fileList.forEach(item=>{
                fileList.push(item.fileid);
            })
            const rs = await this.$https.cloud({
                action:'global',
                type:'setBanner',
                formData:{
                    activitySlideshow: fileList
                }
            });
            if(rs.code==0){
                init = true;
                setTimeout(()=>{init=false;},5*1000)
                this.$message.success(rs.msg);
            }else{
                this.$message.error(rs.msg);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.brandinfo-wrapper {
    padding: 10px;
    height: 100%;
    position: relative;
    background: #fff;

    .header-title {
        font-size: 16px;
        line-height: 22px;
        padding: 13px 0 12px 0;
        border-bottom: 1px solid #D9D9D9;
    }

    .form-content {
        max-width: 800px;
        margin-top: 40px;

        .textarea-content {
            width: 500px;
            height: 300px;
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 10px;
            overflow-y: scroll;
            // white-space: pre-wrap;
        }
    }
}
</style>